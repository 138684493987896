import { BeforeAfterSection } from "./components/before-after-slider/BeforeAfterSection";
import { FixedCta } from "./components/fixed-cta/FixedCta";
import { Footer } from "./components/footer/Footer";
import { Hero } from "./components/hero/Hero";
import { HomeCta } from "./components/home-cta/HomeCta";
import { Services } from "./components/services/Services";
import { Testimonials } from "./components/testimonials/Testimonials";
import { WhyWeDoThis } from "./components/why-we-do-this/WhyWeDoThis";

function App() {
  return (
    <div className="App">
      <FixedCta />
      <div className="content-container">
        <Hero />
        <WhyWeDoThis />
        <Services />
        <HomeCta />
        <BeforeAfterSection />
        <Testimonials />
        <Footer />
      </div>
    </div>
  );
}

export default App;
