import { useBreakpoint } from "../../hooks";

export const FixedCta = () => {
  const { isMaxWidth } = useBreakpoint();

  return (
    <div className="fixed-cta">
      <div className="fixed-cta__content">
        Call now for a free quote:{isMaxWidth("tablet") ? <br /> : " "}
        <a className="fixed-cta__phone" href="tel:18109084044">
          (810) 908 - 4044
        </a>
      </div>
    </div>
  );
};
