import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import { MinusIcon, AddIcon } from "@chakra-ui/icons";
import { useBreakpoint } from "../../hooks";
import ChristmasLights from "../../images/services/christmas-lights.jpg";
import GutterCleanout from "../../images/services/gutter-cleanout.jpg";
import LawnMowing from "../../images/services/lawn-mowing.JPEG";
import ConcreteWashing from "../../images/services/concrete-washing.png";
import HouseWashing from "../../images/services/house-washing.jpg";
import RoofWashing from "../../images/services/roof-washing2.png";
import Mulching from "../../images/services/mulching.jpg";

const services = [
  {
    title: "Lawn Mowing",
    image: LawnMowing,
    description:
      "My lawn mowing service includes the mowing and weedwackinging of your property. Whatever caters to your lawn's needs best, I'm able to schedule weekly or bi-weekly mowing. I also offer a one time mowing option.",
  },
  {
    title: "House Washing",
    image: HouseWashing,
    description:
      "When the sun does not hit a certain side of your house, algae and mold can grow and spread across the exterior of your house. The soft wash system I use sprays the right soaps to kill the algae without damaging the siding.",
  },
  {
    title: "Roof Washing",
    image: RoofWashing,
    description:
      "Black streaks on your roof are caused by hard algae that stain your roof and in time can eat away at the shingles. My soft wash system protects the longevity of your roof and gets rid of the harmful black lines.",
  },
  {
    title: "Concrete Washing",
    image: ConcreteWashing,
    description:
      "Driveways see a lot of activity and because of this, dirt, mold and algae are inevitably going to build up and take away from your home's curb appeal. Luke’s Lawns cleans the driveway with a high pressure surface cleaner which will leave your driveway or other concrete looking brand new.",
  },
  {
    title: "Gutter Clean Out",
    image: GutterCleanout,
    description:
      "Throughout the year your gutters collect a lot of debris especially in the spring and fall. Don’t worry yourself with getting on a ladder and cleaning them out - leave it to me!",
  },
  {
    title: "Christmas Light Installation",
    image: ChristmasLights,
    description:
      "I provide professional grade Christmas light installation. I will custom order the lights to fit your house. When I install Christmas lights I put them up, service them throughout the season, and take them down when you desire.",
  },
  {
    title: "More Services",
    image: Mulching,
    description: (
      <div>
        <ul>
          <li>Leaf clean up</li>
          <li>Brush removal</li>
          <li>Hedge trimming</li>
          <li>Spring / Fall clean up</li>
          <li>Mulching</li>
          <li>Snow blowing</li>
        </ul>
        <p>
          Still don't see a service you're looking for? Give me a call for a
          consultation of your project.
        </p>
      </div>
    ),
  },
];

export const Services = () => {
  const { isMaxWidth } = useBreakpoint();

  return (
    <div className="container services">
      <div className="services__title-container">
        <h2 className="services__title">My Popular Services</h2>
        <hr />
        Services are offered for both residential and commercial properties.
      </div>
      {isMaxWidth("tablet") ? (
        <Accordion defaultIndex={[0]} allowToggle>
          {services.map((service) => (
            <AccordionItem key={service.title}>
              {({ isExpanded }) => (
                <>
                  <AccordionButton className="accordion-button">
                    <h3>{service.title}</h3>
                    {isExpanded ? (
                      <MinusIcon viewBox="0 0 25 25" />
                    ) : (
                      <AddIcon viewBox="0 0 25 25" />
                    )}
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <div className="services__panel-content">
                      <div className="services__panel-image-container">
                        <img src={service.image} alt={service.title} />
                      </div>
                      <div className="services__panel-description-container">
                        <div className="services__panel-description">
                          {service.description}
                        </div>
                      </div>
                    </div>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          ))}
        </Accordion>
      ) : (
        <Tabs variant="line">
          <TabList>
            {services.map((service) => (
              <Tab key={service.title}>{service.title}</Tab>
            ))}
          </TabList>
          <TabPanels>
            {services.map((service) => (
              <TabPanel key={service.title}>
                <div className="services__panel-content">
                  <div className="services__panel-image-container">
                    <img src={service.image} alt={service.title} />
                  </div>
                  <div className="services__panel-description-container">
                    <h3 className="services__panel-title">{service.title}</h3>
                    <div className="services__panel-description">
                      {service.description}
                    </div>
                  </div>
                </div>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      )}
    </div>
  );
};
