import React from "react";
import ReactBeforeSliderComponent from "react-before-after-slider-component";
import "react-before-after-slider-component/dist/build.css";

export const BeforeAfterSlider = ({ firstImage, secondImage }) => {
  const firstImageLink = { imageUrl: firstImage };
  const secondImageLink = { imageUrl: secondImage };

  return (
    <ReactBeforeSliderComponent
      firstImage={firstImageLink}
      secondImage={secondImageLink}
    />
  );
};
