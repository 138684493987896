import { useState } from "react";
import { useBreakpoint } from "../../hooks";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/scss";
import "swiper/scss/autoplay";

const testimonials = [
  {
    text: "Luke did my leaf pick up this year and to say I’m impressed is an understatement!! I’m very particular about my yard, and when Luke was finished my yard was completely spotless and looked top notch. He was very professional and you couldn’t beat the affordable pricing. I highly recommend him for any of your lawn care needs!",
  },
  {
    text: "Luke’s Lawn Care is great!  He came out last minute and did a great job!  Definitely recommend him!",
  },
  {
    text: "Luke was very professional and able to schedule right away. Eager to work and completed the job as asked. Highly recommend asking Luke for any outside work needed!",
  },
];

export const Testimonials = () => {
  const [swiper, setSwiper] = useState(null);
  const { isMinWidth } = useBreakpoint();

  return (
    <div className="container testimonials">
      {isMinWidth("tablet") && (
        <button onClick={() => swiper.slidePrev()}>
          <BsChevronLeft />
        </button>
      )}
      <Swiper
        modules={[Autoplay]}
        slidesPerView={1}
        onSwiper={(s) => setSwiper(s)}
        autoplay={{ delay: 5000 }}
        loop
      >
        {testimonials.map((testimonial) => (
          <SwiperSlide key={testimonial.text}>
            <div className="testimonial">
              <div className="quotes">
                <ImQuotesLeft /> <ImQuotesRight />
              </div>
              <div className="testimonial-text">{testimonial.text}</div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {isMinWidth("tablet") && (
        <button onClick={() => swiper.slideNext()}>
          <BsChevronRight />
        </button>
      )}
    </div>
  );
};
