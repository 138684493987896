import LlLogo from "../../images/ll-logo.png";

export const Hero = () => {
  return (
    <div className="hero">
      <div className="hero__content-container">
        <div className="connector"></div>
        <div className="arrow-left-one"></div>
        <div className="arrow-left-two"></div>
        <div className="hero__content">
          <img className="hero__logo" src={LlLogo} alt="Luke Lawn's Logo" />
          <p className="hero__description">
            You've got better things to do, <br /> let me do the dirty work for
            you!
          </p>
        </div>
      </div>
    </div>
  );
};
