import GardenCleanupBefore from "../../images/before-after/garden-cleanup-before.jpg";
import GardenCleanupAfter from "../../images/before-after/garden-cleanup-after.jpg";
import DeckBefore from "../../images/before-after/deck-before.jpg";
import DeckAfter from "../../images/before-after/deck-after.jpg";
import { BeforeAfterSlider } from "../shared/BeforeAfterSlider";

export const BeforeAfterSection = () => {
  return (
    <div className="bas__container container">
      <div className="bas__title-container">
        <h2 className="bas__title">ALLOW ME TO PROVE MYSELF</h2>
        <hr />
      </div>
      <div className="before-after-sliders">
        <BeforeAfterSlider
          firstImage={GardenCleanupAfter}
          secondImage={GardenCleanupBefore}
        />
        <BeforeAfterSlider firstImage={DeckAfter} secondImage={DeckBefore} />
      </div>
    </div>
  );
};
