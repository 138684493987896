import { createBreakpoint } from "react-use";
import { BREAKPOINTS } from "../constants";

const useInnerBreakpoint = createBreakpoint(BREAKPOINTS);
const breakpointIncludes =
  (breakpoints, breakpoint) =>
  /** @param {keyof typeof BREAKPOINTS} size */
  (size) => {
    if (!Object.keys(BREAKPOINTS).includes(size)) {
      throw new Error(`${size} is not a valid breakpoint`);
    }

    const reduction = breakpoints.reduce((accumulator, currentValue) => {
      if (currentValue === size) return [currentValue];
      if (accumulator.length) return [...accumulator, currentValue];
      return accumulator;
    }, []);

    return reduction.includes(breakpoint);
  };

const smallestToLargestBreakpoints = Object.entries(BREAKPOINTS)
  .sort((a, b) => a[1] - b[1])
  .map((entry) => entry[0]);
const largestToSmallerBreakpoints = smallestToLargestBreakpoints
  .slice()
  .reverse();

export const useBreakpoint = () => {
  const breakpoint = useInnerBreakpoint();

  return {
    breakpoint,
    isMinWidth: breakpointIncludes(smallestToLargestBreakpoints, breakpoint),
    isMaxWidth: breakpointIncludes(largestToSmallerBreakpoints, breakpoint),
  };
};
