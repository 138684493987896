import { CtaBanner } from "../shared/CtaBanner";

export const HomeCta = () => {
  const ctaMessage = (
    <div className="home-cta">
      <div className="home-cta-message">
        Call now for a free quote or to schedule one of my many services
      </div>
      <a className="home-cta-phone" href="tel:18109084044">
        (810) 908 - 4044
      </a>
    </div>
  );

  return <CtaBanner message={ctaMessage} />;
};
