// Shift breakpoints down one since our CSS
// considers a breakpoint from some width and below
// and the React Hook considers a breakpoint from some width and above
// Add 1px to each breakpoint so that the viewport width is inclusive.
export const BREAKPOINTS = {
  mobile: 0,
  tablet: 820,
  laptop: 1025,
  desktop: 1281,
};
