import Luke from "../../images/luke.jpg";

export const WhyWeDoThis = () => {
  return (
    <div className="wwdt container">
      <div className="wwdt__container">
        <div className="wwdt__content-container">
          <div>
            <h2 className="wwdt__title">Why I Do This</h2>
            <hr />
          </div>
          <div className="wwdt__description">
            Here at Luke's Lawns and Exterior Cleaning, I take pride in my work
            no matter the job. I live for the huge smiles from my clients after
            I finish a job for them. <br /> <br /> I strive to make your
            property look top notch, with a great curb appeal. I will treat your
            home like it is my own and make sure you are treated as I would want
            to be!
          </div>
        </div>
        <div className="wwdt__image-container">
          <img src={Luke} alt="placeholder" />
        </div>
      </div>
    </div>
  );
};
